import React from "react"
import { graphql, Link } from "gatsby"
import Nav from "../../../../src/components/Nav"
import HeroSimple from "../../../../src/components/HeroSimple"
//import classnames from "classnames"
import SEO from "../../../../src/components/seo/SEO"
import Contact from "../../../../src/components/Contact"
import Footer from "../../../../src/components/Footer"

const Template = ({ data, pageContext }) => {
  const responses = pageContext.responses.map((item) => {
    return <Link
      className="hover:underline gradient shadow px-8 py-4 mt-4 rounded-full font-bold text-white no-underline hover:no-underline"
      to={item[1]}
      key={item[1]}
    >
      {item[0]}
    </Link>
  })

  return (
    <>
      <SEO />
      <div className="flex">
        <section title="content" className="w-full">
          <div className="gradient">
            <Nav active="faq" />
            <HeroSimple
              heading={'Foire aux questions'}
              subText={"FAQ - QUESTIONS"}
            />
          </div>
          <div className="container mx-auto mb-24">
            <article className=" p-4 md:p-12 lg:p-12 shadow-md">
              <div
                className="markdown-content px-8 lg:px-0 w-full"
              >
                <h1>{pageContext.question}</h1>

                {
                  responses.length > 0 && responses
                }
              </div>
            </article>
          </div>
        </section>
      </div>

      <Contact />
    </>
  )
}

export const query = graphql`
  query {
    footerImage: file(relativePath: { eq: "logo.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Template
